import { useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Col, Row, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useDispatch, useSelector } from 'react-redux';
import { getInvestigations } from 'src/actions';
import { ErrorCode } from 'src/constants';
import { getClientApi } from 'src/data-communication';
import { TradingBlockApplicationUploadedDocumentDto, TradingBlockDocumentNameDto } from 'src/dtos';
import { UnknownError } from 'src/errors';
import { MButton, MFormRadioGroup } from 'src/lib';
import { TradingBlockApplicationCipCategory } from 'src/models';
import { Spacing } from 'src/styles';
import { assertNonNullable, validateInvestigationFile } from 'src/utils';

import * as Styles from './AccountStatus.styles';
import { ApplicationCipFailureDescription } from './ApplicationCipFailureDescription';
import { SampleDocumentDownloadLink } from './SampleDocumentLink';

const clientApi = getClientApi();

interface ApplicationCipFailureFormProps {
  accountHolderId: number;
  category: TradingBlockApplicationCipCategory;
  uploadedDocuments: TradingBlockApplicationUploadedDocumentDto[];
  accountId?: number;
}

export const ApplicationCipFailureForm = ({
  category,
  uploadedDocuments,
  accountHolderId,
  accountId,
}: ApplicationCipFailureFormProps) => {
  const dispatch = useDispatch();
  const authToken: string = useSelector((state: any) => state.auth?.data?.authToken);
  const [documentName, setDocumentName] = useState<string>(category.requestedDocuments?.[0].value ?? '');
  const [documentNameError, setDocumentNameError] = useState<string>();
  const [originalFilename, setOriginalFilename] = useState<string>();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const documentSubmitted = uploadedDocuments?.find(document =>
    category.requestedDocuments?.find(aDocument => aDocument.value === document.documentName),
  );

  const getOptionList = () =>
    category?.requestedDocuments?.map(document => ({
      value: document.value,
      label: document.label,
      description:
        document.value === TradingBlockDocumentNameDto.AffiliateApproval ? (
          <SampleDocumentDownloadLink
            text='Click here to download sample document'
            link='/static/docs/Sample_FINRA_3210_Letter.docx'
            icon='ri-file-line'
          />
        ) : undefined,
    })) ?? [];

  const onUpload = async ({ file, onSuccess, onError }: UploadRequestOption) => {
    try {
      assertNonNullable(accountId, 'Account Id is required');
      assertNonNullable(documentName, 'Document name is required');
      assertNonNullable(originalFilename, 'Original file name is required');
      const formData = new FormData();
      formData.append('file', file);
      formData.append('imageName', originalFilename);
      formData.append('documentName', documentName);

      await clientApi.investigations.uploadApplicationDocument({
        authToken,
        body: formData,
        params: { accountId, accountHolderId },
      });

      if (onSuccess) {
        onSuccess({});
      }
      dispatch(getInvestigations(accountId));
    } catch (error: any) {
      if (error.response) {
        onError?.(new Error(error.response.data.message));

        return;
      }

      if (error?.message) {
        onError?.(error);

        return;
      }

      onError?.(new UnknownError(ErrorCode.UPLOAD_INVESTIGATION_DOCUMENT));
    }
  };

  return (
    <Row className={Spacing.my12}>
      <Col span={24}>
        <ApplicationCipFailureDescription category={category.name} />
      </Col>
      <Col span={24}>
        {!documentSubmitted && (
          <MFormRadioGroup
            label='Select the following document you plan to upload:'
            defaultValue={category.requestedDocuments?.[0].value}
            value={documentName}
            options={getOptionList()}
            onChange={value => {
              setDocumentName(value);
              setDocumentNameError(undefined);
            }}
            error={documentNameError}
            align='vertical'
            className={Spacing.mb0}
          />
        )}
      </Col>
      <Col span={24} className={documentSubmitted ? Spacing.mb24 : Spacing.mb12}>
        <Upload
          accept='.png, .jpg, .jpeg, .pdf'
          maxCount={1}
          name='file'
          multiple={false}
          showUploadList={{ showDownloadIcon: false, showRemoveIcon: false }}
          disabled={Boolean(documentSubmitted) || isUploading}
          customRequest={onUpload}
          defaultFileList={uploadedDocuments
            .filter(document => document.fileName === documentSubmitted?.fileName)
            .map(document => {
              return {
                uid: document.miscDocumentId.toString(),
                name: document.originalFileName,
                status: 'done',
              };
            })}
          beforeUpload={async (file: RcFile) => {
            try {
              await validateInvestigationFile(file);
            } catch (error: any) {
              setDocumentNameError(error.message);

              return Upload.LIST_IGNORE;
            }
          }}
          onChange={info => {
            if (info.file.status === 'uploading') {
              setOriginalFilename(info.file.name);
              setIsUploading(true);
            }

            // NOTE: info.file.erorr equals the error passed down from the onError() callback on unUpload() method
            if (info.file.status === 'error') {
              setDocumentNameError(info.file.error?.message);
              setIsUploading(false);
            }

            if (info.file.status === 'done') {
              setIsUploading(false);
              setDocumentNameError(undefined);
            }
          }}
          className={Styles.upload}>
          {!documentSubmitted && (
            <MButton type='secondary' loading={isUploading} disabled={isUploading}>
              <UploadOutlined style={{ height: 16, width: 16 }} /> Upload
            </MButton>
          )}
        </Upload>
      </Col>
    </Row>
  );
};
