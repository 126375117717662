const {
  REACT_APP_CC_API_BASE_URL,
  REACT_APP_MYIPO_API_BASE_URL,
  REACT_APP_TRADING_BLOCK_WEB_SOCKET_URL,
  REACT_APP_STRIPE_PUBLIC_API_KEY,
  REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
} = process.env;

export namespace EnvConstant {
  export const CC_API_BASE_URL = REACT_APP_CC_API_BASE_URL || 'http://localhost:8080/v1/api';
  export const MYIPO_API_BASE_URL = REACT_APP_MYIPO_API_BASE_URL || 'http://localhost:8081/v1/api';
  export const TRADING_BLOCK_WEB_SOCKET_URL = REACT_APP_TRADING_BLOCK_WEB_SOCKET_URL;
  export const STRIPE_PUBLIC_API_KEY = REACT_APP_STRIPE_PUBLIC_API_KEY || '';
  export const GOOGLE_ANALYTICS_MEASUREMENT_ID = REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || '';
}
