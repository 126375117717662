import React from 'react';

import { cx } from '@emotion/css';
import { Col, Row, Space } from 'antd';
import { NumericFormat } from 'react-number-format';

import { ModalFormItem } from '../../../../../../models/modal-form-item.model';
import { PriceData } from '../../../../../../models/quote-media.model';

import { getSecuritiesStatisticsFields } from './SecuritiesStatisticsFields';
import * as Styles from './styles';

interface SecuritiesStatisticsProps {
  data: PriceData;
  change: number;
}

export const SecuritiesStatistics = ({ data }: SecuritiesStatisticsProps) => {
  function generateStatisticFields(data: any) {
    return getSecuritiesStatisticsFields()?.map((field: ModalFormItem) => {
      const value = ['week52high', 'week52low'].includes(field.key) ? data?.[field.key]['content'] : data?.[field.key];

      return (
        <Col span={8} className={Styles.alignCenter} key={field.key}>
          <Space size={0} direction='vertical'>
            <NumericFormat
              value={!!value ? value?.toFixed(field.props?.['decimalPlaces']) : '-'}
              suffix={field.props && field.props['isPercentage'] ? '%' : ''}
              prefix={
                !!value && value >= 0 && field.props?.['isPercentage'] === true
                  ? '+'
                  : !!value && value >= 0 && field.props?.['isCurrency'] === true
                  ? '$'
                  : ''
              }
              displayType={'text'}
              decimalScale={field.props && field.props['decimalPlaces'] !== null ? field.props['decimalPlaces'] : 2}
              className={cx(
                Styles.valueStyling,
                !!value && value >= 0 && field.props?.['isPercentage'] === true
                  ? Styles.pozitiveValue
                  : value && field.props?.['isPercentage'] === true
                  ? Styles.negativeValue
                  : '',
              )}
            />
            <span className={Styles.labelStyling}>{field.props?.label}</span>
          </Space>
        </Col>
      );
    });
  }

  return <Row>{generateStatisticFields(data)}</Row>;
};
