import { useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Col, Row, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useDispatch, useSelector } from 'react-redux';
import { getInvestigations } from 'src/actions';
import { ErrorCode } from 'src/constants';
import { getClientApi } from 'src/data-communication/ClientApi';
import { TradingBlockDocumentNameDto, TradingBlockInvestigationUploadedDocumentDto } from 'src/dtos';
import { UnknownError } from 'src/errors';
import { MButton, MFormRadioGroup } from 'src/lib';
import { TradingBlockInvestigationCipCategory } from 'src/models';
import { Spacing } from 'src/styles';
import { assertNonNullable, validateInvestigationFile } from 'src/utils';

import * as Styles from './AccountStatus.styles';
import { InvestigationCipFailureDescription } from './InvestigationCipFailureDescription';
import { SampleDocumentDownloadLink } from './SampleDocumentLink';

const clientApi = getClientApi();

interface InvestigationCipFailureFormProps {
  investigationId: number;
  category: TradingBlockInvestigationCipCategory;
  uploadedDocuments: TradingBlockInvestigationUploadedDocumentDto[];
  accountId?: number;
}

export const InvestigationCipFailureForm = ({
  investigationId,
  category,
  uploadedDocuments,
  accountId,
}: InvestigationCipFailureFormProps) => {
  const dispatch = useDispatch();
  const authToken: string = useSelector((state: any) => state.auth?.data?.authToken);
  const [documentName, setDocumentName] = useState<string>(category.requestedDocuments?.[0].value ?? '');
  const [documentNameError, setDocumentNameError] = useState<string>();
  const [originalFilename, setOriginalFilename] = useState<string>();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const documentSubmitted = uploadedDocuments?.find(document =>
    category.requestedDocuments?.find(aDocument => aDocument.value === document.documentName),
  );

  const getOptionList = () => {
    return (
      category?.requestedDocuments?.map(document => ({
        value: document.value,
        label: document.label,
        description:
          document.value === TradingBlockDocumentNameDto.IrsW9Form ? (
            <SampleDocumentDownloadLink
              text='Download IRS W9 Form'
              link='https://www.irs.gov/pub/irs-pdf/fw9.pdf'
              icon='ri-download-2-fill'
            />
          ) : undefined,
      })) ?? []
    );
  };

  const onUpload = async ({ file, onSuccess, onError }: UploadRequestOption) => {
    assertNonNullable(accountId, 'Account Id is required');
    assertNonNullable(documentName, 'Document name is required');
    assertNonNullable(originalFilename, 'Original file name is required');

    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('imageName', originalFilename);
      formData.append('documentName', documentName);

      await clientApi.investigations.uploadDocument({
        authToken,
        body: formData,
        params: { accountId, investigationId },
      });

      if (onSuccess) {
        onSuccess({});
      }
      dispatch(getInvestigations(accountId));
    } catch (error: any) {
      if (error.response) {
        onError?.(new Error(error.response.data.message));

        return;
      }

      if (error?.message) {
        onError?.(error);

        return;
      }

      onError?.(new UnknownError(ErrorCode.UPLOAD_INVESTIGATION_DOCUMENT));
    }
  };

  return (
    <Row className={Spacing.my12}>
      <Col span={24}>
        <InvestigationCipFailureDescription category={category.name} />
      </Col>
      {!documentSubmitted && (
        <Col span={24}>
          <MFormRadioGroup
            label='Select the following document you plan to upload:'
            defaultValue={category.requestedDocuments?.[0].value}
            value={documentName}
            options={getOptionList()}
            onChange={value => {
              setDocumentName(value);
              setDocumentNameError(undefined);
            }}
            error={documentNameError}
            align='vertical'
            className={Spacing.mb0}
          />
        </Col>
      )}
      <Col span={24} className={documentSubmitted ? Spacing.mb24 : Spacing.mb12}>
        <Upload
          accept='.png, .jpg, .jpeg, .pdf'
          maxCount={1}
          name='file'
          multiple={false}
          showUploadList={{ showDownloadIcon: false, showRemoveIcon: false }}
          disabled={Boolean(documentSubmitted) || isUploading || !documentName}
          customRequest={onUpload}
          defaultFileList={uploadedDocuments
            .filter(document => document.fileName === documentSubmitted?.fileName)
            .map(document => {
              return {
                uid: document.miscDocumentId.toString(),
                name: document.originalFileName,
                status: 'done',
              };
            })}
          beforeUpload={async (file: RcFile) => {
            try {
              await validateInvestigationFile(file);
            } catch (error: any) {
              setDocumentNameError(error.message);

              return Upload.LIST_IGNORE;
            }
          }}
          onChange={info => {
            if (info.file.status === 'uploading') {
              setOriginalFilename(info.file.name);
              setIsUploading(true);
            }

            // NOTE: info.file.erorr equals the error passed down from the onError() callback on unUpload() method
            if (info.file.status === 'error') {
              setDocumentNameError(info.file.error?.message);
              setIsUploading(false);
            }

            if (info.file.status === 'done') {
              setDocumentNameError(undefined);
              setIsUploading(false);
            }
          }}
          className={Styles.upload}>
          {!documentSubmitted && (
            <MButton type='secondary' disabled={isUploading || !documentName}>
              <UploadOutlined style={{ height: 16, width: 16 }} /> Upload
            </MButton>
          )}
        </Upload>
      </Col>
    </Row>
  );
};
