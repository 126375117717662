import { css } from '@emotion/css';
import {
  Spacing,
  AlignItems,
  JustifyContent,
  ScreenBreakpoint,
  Display,
  ColorConstant,
  Font,
  FontWeight,
  TextAlign,
  AlignSelf,
  BaseStyle,
  FlexDirection,
} from 'src/styles';

export const container = css`
  background-color: #ffffff;
  ${Spacing.mb32}
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${Spacing.my0}
    ${Spacing.mxAuto}
    width: 90%;
  }
`;

export const main = css`
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${AlignItems.center}
    ${JustifyContent.center}
  }
`;

export const logoContainer = css`
  ${Display.flex}
  ${JustifyContent.center}
`;

export const logo = css`
  ${Spacing.mt40}
  ${Spacing.mb24}
  ${Display.flex}
`;

export const title = css`
  ${FontWeight.bold}
  ${Font.h6}
  ${TextAlign.center}
  color: ${ColorConstant.BRAND6};
`;

export const subtitle = css`
  ${Spacing.my0}
  ${Spacing.mxAuto}
  ${Font.h1}
  ${Spacing.mb40}
  ${TextAlign.center}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    word-wrap: break-word;
    ${Spacing.p4}
    width: 100%;
  }

  @media (min-width: ${ScreenBreakpoint.laptop.min}) {
    max-width: 50%;
    min-width: 50%;
  }
`;

export const footer = css`
  ${Spacing.my0}
  ${Spacing.mxAuto}
  ${Font.lg}
  ${Spacing.mb40}
  ${TextAlign.left}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    word-wrap: break-word;
    ${Spacing.p4}
    width: 100%;
  }

  @media (min-width: ${ScreenBreakpoint.laptop.min}) {
    max-width: 50%;
    min-width: 50%;
  }
`;

export const cardContainer = css`
  ${Display.flex}
  ${FlexDirection.row}
  ${AlignItems.center}
  ${JustifyContent.center}

  
  @media (min-width: ${ScreenBreakpoint.desktop.max}) {
    width: 50%;
    margin-left: 25%;
  }

  @media (max-width: ${ScreenBreakpoint.desktop.max}) {
    width: 76%;
    margin-left: 12%;
  }

  @media (max-width: ${ScreenBreakpoint.laptop.max}) {
    width: 90%
    margin-left: 5%;
  }

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${FlexDirection.columnReverse}
    width: 100%;
    margin: 0;
  }
`;

export const card = css`
  margin-left: 20%;
  width: 60%;

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    width: 100%;
    margin: 0;
  }
`;

export const rightSlot = css`
  width: 18%;
  margin-left: 2%;

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    width: 80%;
    margin: 0;
  }
`;

export const cardContentContainer = css`
  width: 100%;
  ${Spacing.p20}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    ${AlignSelf.center}
  }
`;

export const cardTitle = css`
  ${FontWeight.semibold}
  ${Font.h3}
  ${Spacing.mb16}
  color: ${ColorConstant.GRAY10};
  ${BaseStyle.level}
`;

export const cardSubtitle = css`
  ${Spacing.my0}
  ${Spacing.mxAuto}
  ${Font.lg}
  ${Spacing.mb40}
  ${TextAlign.center}

  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    word-wrap: break-word;
    ${Spacing.p4}
    width: 100%;
    ${TextAlign.left}
  }
`;

export const tabs = css`
  margin: auto;
  ${Spacing.my16}
  ${Display.table}
  width: 28rem;
  table-layout: fixed;
  @media (max-width: ${ScreenBreakpoint.mobile.max}) {
    width: 100%;
  }
`;

export const tab = css`
  ${Display.tableCell}
  ${TextAlign.center}
  ${Font.lg}
  color: ${ColorConstant.GRAY6};
  mix-blend-mode: normal;
`;

export const activeTab = css`
  ${FontWeight.bold}
  ${Font.lg}
  ${Display.tableCell}
  ${TextAlign.center}
  color: ${ColorConstant.BRAND6};
  mix-blend-mode: normal;
`;
